// AJAX to get the user company name and cache

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useRouter } from 'next/router';
import useUserMetadata from './useUserData';
import { getAccountManager } from '@/lib/repository/machinesRepository';
import { IProductAccountManager } from '@/lib/types/api';
import { DEFAULT_ACCOUNT_MANAGER } from '@/lib/repository/constants';

export default function useAccountManagerByEmail(email?: string) {
  const { data: accountManager } = useQuery(
    ['account_manager', email],
    () => getAccountManagerByEmail(email),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!email,
    }
  );

  return { accountManager };
}

export function useAccountManagerByLocale() {
  const { metadata, isLoading: isLoadingMetadata } = useUserMetadata();
  const { locale } = useRouter();
  const query = useQuery(
    ['account_manager', locale, metadata?.locale ? metadata.locale : null],
    () => getAccountManagerByLocale(locale, metadata?.locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!locale && !isLoadingMetadata,
    }
  );

  return { ...query, data: query.data ?? DEFAULT_ACCOUNT_MANAGER };
}

export function useAccountManagerByDomain() {
  const { locale } = useRouter();
  const query = useQuery(
    ['account_manager', locale],
    () => getAccountManagerByLocale(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!locale,
    }
  );

  return { ...query, data: query.data ?? DEFAULT_ACCOUNT_MANAGER };
}

export async function searchAccountManagers<T>(
  condition: (accountManager: IProductAccountManager) => boolean,
  defaultValue: T
): Promise<IProductAccountManager | T> {
  const accountManagers = await getCacheAccountManagers();
  const accountManager = accountManagers.find(condition);
  if (accountManager) {
    return accountManager;
  }
  return defaultValue;
}

export async function getAccountManagerByEmail(email?: string) {
  if (!email) {
    return DEFAULT_ACCOUNT_MANAGER;
  }

  return searchAccountManagers(
    (manager) => manager.email === email,
    DEFAULT_ACCOUNT_MANAGER
  );
}

export async function getAccountManagerByLocale(
  TLDlocale?: string,
  userLocale?: string
) {
  try {
    return await getAccountManager(TLDlocale, userLocale);
  } catch (error) {
    console.error('getAccountManagerByLocale', error);
    return DEFAULT_ACCOUNT_MANAGER;
  }
}

export function getCacheAccountManagers(): Promise<IProductAccountManager[]> {
  const api =
    process.env.NODE_ENV === 'production'
      ? 'https://www.gindumac.com/api/'
      : 'https://staging.gindumac.com/api/';
  return axios.get(api + 'account-manager').then((res) => {
    return res.data;
  });
}
