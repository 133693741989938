import { useAppSelector } from '@/redux/hooks';
import { AppState } from '@/redux/store';
import GoogleAnalytics from './GoogleAnalytics';
import { SentryUserSetter } from './SentryUserSetter';

export function Tracking() {
  const essentialAndSentryCookiesConsent = useAppSelector(
    (state: AppState) => state.cookieConsent.essentialAndSentryCookiesConsent
  );
  return (
    <>
      <GoogleAnalytics />
      {process.env.NEXT_PUBLIC_ENABLE_TRACKING &&
      process.env.NEXT_PUBLIC_ENABLE_TRACKING != 'false' &&
      essentialAndSentryCookiesConsent == 'yes' ? (
        <SentryUserSetter />
      ) : null}
    </>
  );
}
