import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Menu } from '@headlessui/react';
import {
  FaPlus,
  FaComments,
  FaTimes,
  FaPhoneAlt,
  FaWhatsapp,
} from 'react-icons/fa';
import {
  useAccountManagerByDomain,
  useAccountManagerByLocale,
} from '@/lib/hooks/useAccountManager';
import { useWindowSize } from 'react-use';
import { getWhatsappLink } from '@/components/product/ProductAccountManager';
import classNames from 'classnames';
import { telephoneLink } from '@/lib/data-transformer/gindumacUtils';
import { useRouter } from 'next-translate-routes-multi-domain';
import useScrollPosition from '@/lib/hooks/useScrollPosition';
import useTranslate from '@/lib/hooks/useTranslate';
import Image from 'next/image';
import { useUser } from '@auth0/nextjs-auth0/client';

const GlobalContactUsButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showContactAlert, setShowContactAlert] = useState(false); // New state for delayed appearance
  // hidden if url is /product/[id] and scroll position is < 10
  const disableInPages = ['/product/[machine_id]'];
  const router = useRouter();
  const iconTimerRef = useRef<NodeJS.Timeout | null>(null);
  const notificationTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { user } = useUser();
  // load manager info into main so be able to load profile picture
  const { data: accountManagerByLocale } = useAccountManagerByLocale();

  const { data: accountManagerByDomain } = useAccountManagerByDomain();

  useEffect(() => {
    // Check if the alert has been shown before (using local storage)
    const hasSeenContactAlert = localStorage.getItem('hasSeenContactAlert');
    const hasCloseContactAlert = localStorage.getItem('hasCloseContactAlert');

    if (!hasSeenContactAlert) {
      iconTimerRef.current = setTimeout(() => {
        setShowIcon(true);
      }, 1000);

      notificationTimerRef.current = setTimeout(() => {
        setShowContactAlert(true);
        setShowIcon(false);
        localStorage.setItem('hasSeenContactAlert', 'true');
      }, 10000);
    } else if (!hasCloseContactAlert) {
      setShowContactAlert(true);
      setShowIcon(false);
    } else {
      setShowIcon(true);
      setShowContactAlert(false);
    }

    return () => {
      if (iconTimerRef.current) {
        clearTimeout(iconTimerRef.current);
      }
      if (notificationTimerRef.current) {
        clearTimeout(notificationTimerRef.current);
      }
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setShowIcon(!showIcon);
    setShowContactAlert(false);
    if (iconTimerRef.current) {
      clearTimeout(iconTimerRef.current);
    }
    if (notificationTimerRef.current) {
      clearTimeout(notificationTimerRef.current);
    }
  };

  const isMobileScreen = useWindowSize().width < 640;
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetElement = event.target as Element | null; // Ensure it's an Element
      if (
        menuRef.current &&
        !menuRef.current.contains(targetElement) &&
        !targetElement?.closest('button') // Prevents closing when clicking inside the button
      ) {
        setIsOpen(false);
        setShowIcon(true);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      setShowIcon(false);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const accountManager = user ? accountManagerByLocale : accountManagerByDomain;
  const position = useScrollPosition();
  return (
    <div
      className={classNames(
        'fixed z-50  flex items-center',
        isOpen && isMobileScreen ? 'right-4 top-24 ' : 'bottom-4 left-4',
        disableInPages.includes(router.pathname) && position < 50 && 'hidden'
      )}
    >
      <div>
        {/* Button v2 */}
        <button
          className={classNames(
            'z-50 flex items-center justify-center rounded-full border text-white shadow-lg',
            isOpen
              ? 'bg-brand-500 hover:bg-brand-800'
              : 'bg-green-500  hover:bg-green-600'
          )}
          onClick={(e) => {
            e.stopPropagation();
            toggleMenu();
          }}
        >
          {/* Profile Picture */}
          {isOpen ? (
            <div className='flex h-16 w-16 items-center justify-center space-x-1'>
              <FaPlus className='h-6 w-6 rotate-45' />
            </div>
          ) : (
            <div className='relative h-16 w-16 overflow-hidden rounded-full bg-gray-300 shadow-md'>
              <Image
                layout='fill'
                objectFit='cover'
                src={accountManager.profile_picture}
                alt={accountManager.name}
                className='rounded-full'
              />
            </div>
          )}

          {/* Comment icon */}
          {showIcon && (
            <div className='ml-2 flex space-x-1'>
              <FaComments className='mr-4 h-8 w-8 ' />
            </div>
          )}
        </button>
      </div>
      {showContactAlert && (
        <Menu as='div' className='absolute bottom-20 left-4 ml-8' ref={menuRef}>
          <ContactAlertInicial
            setShowContactAlert={setShowContactAlert}
            setIsOpen={setIsOpen}
            setShowIcon={setShowIcon}
          />
        </Menu>
      )}
      {isOpen && !isMobileScreen && (
        <Menu
          as='div'
          className='absolute bottom-20 left-4 w-64 rounded-lg border border-gray-300 bg-white shadow-lg'
          ref={menuRef}
        >
          <AccountManagerMenu />
        </Menu>
      )}

      {isOpen && isMobileScreen && (
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className='relative z-40'
        >
          <div className='fixed inset-0 bg-white/70' aria-hidden='true' />

          <div className='fixed inset-0 flex items-center justify-center p-4 '>
            <Dialog.Panel className='bg-white shadow-lg'>
              <Dialog.Title className='rounded'>
                <AccountManagerMenu showAllLinks />
              </Dialog.Title>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </div>
  );
};

function ContactAlertInicial({
  setShowContactAlert,
  setIsOpen,
  setShowIcon,
}: {
  setShowContactAlert: (value: boolean) => void;
  setIsOpen: (value: boolean) => void;
  setShowIcon: (value: boolean) => void;
}) {
  const { t } = useTranslate();
  return (
    <div
      className='bottom-20 flex w-52 cursor-pointer rounded-lg border border-gray-300 bg-white p-2 shadow-md'
      onClick={() => {
        setShowContactAlert(false);
        setIsOpen(true);
      }}
    >
      {/* Close Button - Prevents Click Propagation */}
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowContactAlert(false);
          setShowIcon(true);
          localStorage.setItem('hasCloseContactAlert', 'true');
        }}
        className='absolute right-1 top-2 text-gray-500 transition-all hover:text-gray-700'
      >
        <FaTimes className='h-4 w-4' />
      </button>
      {/* Chat Bubble */}
      <div className='relative max-w-[230px] rounded-xl border-gray-300 px-3 py-3 text-center text-gray-700'>
        <h4>{t('global:Need help')}</h4>
      </div>
      {/* Triangle at the bottom-left */}
      <div className='absolute -bottom-2 left-3 h-1 w-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-gray-300' />
      <div className='absolute -bottom-1 left-3 h-1 w-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-white' />
    </div>
  );
}

function AccountManagerMenu({
  showAllLinks = false,
}: {
  showAllLinks?: boolean;
}) {
  const { user } = useUser();
  const { data: accountManagerByLocale, isLoading: isLoadingByLocale } =
    useAccountManagerByLocale();
  const { data: accountManagerByDomain, isLoading: isLoadingByDomain } =
    useAccountManagerByDomain();

  const isLoading = isLoadingByLocale && isLoadingByDomain;

  const accountManager = user ? accountManagerByLocale : accountManagerByDomain;
  const { t } = useTranslate();
  if (isLoading) {
    //spinner when loading
    return (
      <div className='flex items-center justify-center p-6'>
        <div className='h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent' />
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col items-center p-4'>
        <div className='relative mx-auto mb-4 h-20 w-20 rounded-full bg-gray-300'>
          <Image
            layout='fill'
            objectFit='cover'
            src={accountManager.profile_picture}
            alt={accountManager.name}
            className='rounded-full'
          />
        </div>
        <h3 className='font-semibold'>{accountManager.name}</h3>

        <p className='text-center text-2xl text-gray-600 sm:text-lg'>
          {accountManager.position}
        </p>

        <div className='mt-2 text-center'>
          <h5>
            <a
              href={'tel:' + accountManager.phone}
              className='text-xl text-blue-800 underline sm:text-lg'
            >
              {accountManager.phone}
            </a>
          </h5>
          <h5>
            <a
              className='text-xl text-blue-800 underline underline-offset-2 sm:text-lg'
              href={'mailto:' + accountManager.email}
              target='_blank'
              rel='noreferrer'
            >
              {accountManager.email}
            </a>
          </h5>
        </div>
      </div>
      <div className='flex flex-col justify-center space-y-2 p-4'>
        <a
          className='w-full'
          href={getWhatsappLink(accountManager)}
          target='_blank'
          rel='noreferrer'
        >
          <button className='flex w-full items-center justify-center rounded-lg bg-green-500 px-4 py-2 font-primary text-white hover:bg-green-600'>
            <FaWhatsapp className='mr-2 h-8' />
            <span>{t('products:WhatsApp Chat')}</span>
          </button>
        </a>
        {showAllLinks && (
          <a
            className='w-full'
            href={telephoneLink(accountManager.phone)}
            target='_blank'
            rel='noreferrer'
          >
            <button className='flex w-full items-center justify-center rounded-lg bg-brand-400 px-4 py-2 font-primary text-white hover:bg-brand-500'>
              <FaPhoneAlt className='mr-2 h-6' />
              <span>{t('products:Call')}</span>
            </button>
          </a>
        )}
      </div>
    </>
  );
}

export default GlobalContactUsButton;
