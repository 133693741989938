import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const BrandSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgb(var(--tw-color-primary-900))',
    '&:hover': {
      backgroundColor: `rgba(var(--tw-color-primary-900), ${theme.palette.action.hoverOpacity})`,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
    color: 'rgb(var(--tw-color-primary-900))',
    opacity: 0.7,
    pointerEvents: 'all',
    cursor: 'not-allowed',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgb(var(--tw-color-primary-900))',
  },
}));

export default BrandSwitch;
