import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ConsentStatus = 'yes' | 'no' | null;

export interface CookieConsentState {
  essentialAndSentryCookiesConsent: ConsentStatus;
  googleAnalyticsCookiesConsent: ConsentStatus;
  googleAdvertisingCookiesConsent: ConsentStatus;
}

export const initialState: CookieConsentState = {
  essentialAndSentryCookiesConsent: null,
  googleAnalyticsCookiesConsent: null,
  googleAdvertisingCookiesConsent: null,
};

export const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {
    setEssentialAndSentryCookiesConsent: (
      state,
      action: PayloadAction<ConsentStatus>
    ) => {
      state.essentialAndSentryCookiesConsent = action.payload;
    },
    setGoogleAnalyticsCookiesConsent: (
      state,
      action: PayloadAction<ConsentStatus>
    ) => {
      state.googleAnalyticsCookiesConsent = action.payload;
    },
    setGoogleAdvertisingCookiesConsent: (
      state,
      action: PayloadAction<ConsentStatus>
    ) => {
      state.googleAdvertisingCookiesConsent = action.payload;
    },
  },
});

export const {
  setEssentialAndSentryCookiesConsent,
  setGoogleAnalyticsCookiesConsent,
  setGoogleAdvertisingCookiesConsent,
} = cookieConsentSlice.actions;

export default cookieConsentSlice.reducer;
