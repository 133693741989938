import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Modal, Collapse, Paper, Divider } from '@mui/material';
import store from 'store2';
import { AppState } from '@/redux/store';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  setEssentialAndSentryCookiesConsent,
  setGoogleAdvertisingCookiesConsent,
  setGoogleAnalyticsCookiesConsent,
} from './cookieConsentSlice';
import { isBoolean, isString } from 'lodash-es';
import { updateGTMAnalyticsConsent } from './GoogleAnalytics';
import { updateGTMAdvertisingConsent } from './GoogleAnalytics';
import BrandSwitch from '../mygindumac/ui/BrandSwitch';
import useTranslation from 'next-translate/useTranslation';

interface CookieSettings {
  analytics: boolean;
  advertising: boolean;
}

export default function CookieConsent() {
  const { t } = useTranslation();
  const [cookieConsentVisible, setCookieConsentVisible] = useState(true);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [cookieSettings, setCookieSettings] = useState<CookieSettings>({
    analytics: false,
    advertising: false,
  });

  const dispatch = useAppDispatch();

  const essentialAndSentryCookiesConsent = useAppSelector(
    (state: AppState) => state.cookieConsent.essentialAndSentryCookiesConsent
  );
  const googleAdvertisingCookiesConsent = useAppSelector(
    (state: AppState) => state.cookieConsent.googleAdvertisingCookiesConsent
  );
  const googleAnalyticsCookiesConsent = useAppSelector(
    (state: AppState) => state.cookieConsent.googleAnalyticsCookiesConsent
  );

  useEffect(() => {
    const essentialAndSentryCookiesConsentStored = store.get(
      'essentialAndSentryCookiesConsent'
    );
    const googleAdvertisingCookiesConsentStored = store.get(
      'googleAnalyticsCookiesConsent'
    );
    const googleAnalyticsCookiesConsentStored = store.get(
      'googleAdvertisingCookiesConsent'
    );
    if (
      isBoolean(essentialAndSentryCookiesConsentStored) ||
      isString(essentialAndSentryCookiesConsentStored)
    ) {
      const consent =
        essentialAndSentryCookiesConsentStored === 'yes' ||
        essentialAndSentryCookiesConsentStored === true;
      dispatch(setEssentialAndSentryCookiesConsent(consent ? 'yes' : 'no'));
    }
    if (
      isBoolean(googleAdvertisingCookiesConsentStored) ||
      isString(googleAdvertisingCookiesConsentStored)
    ) {
      const consent =
        googleAdvertisingCookiesConsentStored === 'yes' ||
        googleAdvertisingCookiesConsentStored === true;
      dispatch(setGoogleAdvertisingCookiesConsent(consent ? 'yes' : 'no'));
      updateGTMAdvertisingConsent(consent);
    }
    if (
      isBoolean(googleAnalyticsCookiesConsentStored) ||
      isString(googleAnalyticsCookiesConsentStored)
    ) {
      const consent =
        googleAnalyticsCookiesConsentStored === 'yes' ||
        googleAnalyticsCookiesConsentStored === true;
      dispatch(setGoogleAnalyticsCookiesConsent(consent ? 'yes' : 'no'));
      updateGTMAnalyticsConsent(consent);
    }
  }, [dispatch]);

  const acceptAll = () => {
    setCookieSettings({ analytics: true, advertising: true });
    setCookieConsentVisible(false);
    store.set('essentialAndSentryCookiesConsent', true);
    store.set('googleAnalyticsCookiesConsent', true);
    store.set('googleAdvertisingCookiesConsent', true);
    dispatch(setEssentialAndSentryCookiesConsent('yes'));
    dispatch(setGoogleAnalyticsCookiesConsent('yes'));
    dispatch(setGoogleAdvertisingCookiesConsent('yes'));
    updateGTMAdvertisingConsent(true);
    updateGTMAnalyticsConsent(true);
  };

  const saveSettings = () => {
    console.log('Settings saved:', cookieSettings);
    store.set('essentialAndSentryCookiesConsent', true);
    store.set('googleAnalyticsCookiesConsent', cookieSettings.analytics);
    store.set('googleAdvertisingCookiesConsent', cookieSettings.advertising);
    dispatch(setEssentialAndSentryCookiesConsent('yes'));
    dispatch(
      setGoogleAnalyticsCookiesConsent(cookieSettings.analytics ? 'yes' : 'no')
    );
    dispatch(
      setGoogleAdvertisingCookiesConsent(
        cookieSettings.advertising ? 'yes' : 'no'
      )
    );
    updateGTMAdvertisingConsent(true);
    updateGTMAnalyticsConsent(cookieSettings.advertising);
    setCookieConsentVisible(false);
    setSettingsModalOpen(false);
  };

  const handleSwitchChange =
    (key: keyof CookieSettings) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCookieSettings((prev) => ({ ...prev, [key]: event.target.checked }));
    };

  return (
    <>
      {(essentialAndSentryCookiesConsent == null ||
        googleAdvertisingCookiesConsent == null ||
        googleAnalyticsCookiesConsent == null) &&
        process.env.NEXT_PUBLIC_ENABLE_TRACKING !== 'false' && (
          <>
            {cookieConsentVisible && (
              <Modal open={cookieConsentVisible} sx={{ zIndex: 40 }}>
                <div className='absolute left-1/2 top-1/2 flex w-full max-w-[500px] -translate-x-1/2 -translate-y-1/2 transform flex-col gap-4 rounded-lg bg-white p-4 shadow-lg outline-none'>
                  <h2>{t('products:COOKIE_CONSENT_MODAL_TITLE')}</h2>
                  <p>{t('products:COOKIE_CONSENT_MODAL_TEXT_1')}</p>
                  <div className='flex justify-end gap-2'>
                    <button
                      className={
                        'flex items-center justify-center rounded border-[1px] border-brand-500 px-4 py-1 font-primary uppercase transition duration-300 hover:bg-brand-500 hover:text-white'
                      }
                      onClick={() => setSettingsModalOpen(true)}
                    >
                      {t('products:COOKIE_CONSENT_MODAL_BUTTON_SETTINGS')}
                    </button>
                    <button
                      className={
                        'flex items-center justify-center rounded border-[1px] border-brand-500 bg-brand-500 px-4 py-1 font-primary uppercase text-white transition duration-300 hover:bg-opacity-90 hover:text-white'
                      }
                      onClick={acceptAll}
                    >
                      {t('products:COOKIE_CONSENT_MODAL_BUTTON_ACCEPT')}
                    </button>
                  </div>
                </div>
              </Modal>
            )}

            <Modal
              open={settingsModalOpen}
              onClose={() => setSettingsModalOpen(false)}
            >
              <div className='absolute left-1/2 top-1/2 max-h-[90vh] w-full max-w-[600px] -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-lg bg-white p-4 shadow-lg'>
                <div className='flex items-center justify-between'>
                  <h2>{t('products:COOKIE_CONSENT_MODAL_SETTINGS_TITLE')}</h2>
                  <button
                    className='h-10 w-10 rounded-full font-primary text-xl font-bold uppercase text-brand-400 hover:bg-slate-100'
                    onClick={() => setSettingsModalOpen(false)}
                  >
                    ×
                  </button>
                </div>
                <Divider sx={{ my: 2 }} />
                <CookiePreferenceCard
                  title={t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TITLE')}
                  description={t(
                    'products:COOKIE_CONSENT_MODAL_SETTINGS_1_DESCRIPTION'
                  )}
                  handleSwitchChange={handleSwitchChange('analytics')}
                  isChecked={true}
                  isDisabled={true}
                >
                  <span className='text-sm text-slate-500'>
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_1')}
                    <ul className='list-disc pl-5'>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_2')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_3')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_4')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_5')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_6')}
                      </li>
                    </ul>
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_1_TEXT_7')}
                  </span>
                </CookiePreferenceCard>
                <CookiePreferenceCard
                  title={t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TITLE')}
                  description={t(
                    'products:COOKIE_CONSENT_MODAL_SETTINGS_2_DESCRIPTION'
                  )}
                  handleSwitchChange={handleSwitchChange('analytics')}
                  isChecked={cookieSettings.analytics}
                >
                  <span className='text-sm text-slate-500'>
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TEXT_1')}
                    <ul className='list-disc pl-5'>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TEXT_2')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TEXT_3')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TEXT_4')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TEXT_5')}
                      </li>
                    </ul>
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_2_TEXT_6')}
                  </span>
                </CookiePreferenceCard>
                <CookiePreferenceCard
                  title={t('products:COOKIE_CONSENT_MODAL_SETTINGS_3_TITLE')}
                  description={t(
                    'products:COOKIE_CONSENT_MODAL_SETTINGS_3_DESCRIPTION'
                  )}
                  handleSwitchChange={handleSwitchChange('advertising')}
                  isChecked={cookieSettings.advertising}
                >
                  <span className='text-sm text-slate-500'>
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_3_TEXT_1')}
                    <ul className='list-disc pl-5'>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_3_TEXT_2')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_3_TEXT_3')}
                      </li>
                      <li>
                        {t('products:COOKIE_CONSENT_MODAL_SETTINGS_3_TEXT_4')}
                      </li>
                    </ul>
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_3_TEXT_5')}
                  </span>
                </CookiePreferenceCard>
                <Box display='flex' justifyContent='flex-end' gap={2}>
                  <button
                    className={
                      'flex items-center justify-center rounded border-[1px] border-brand-500 px-4 py-1 font-primary uppercase transition duration-300 hover:bg-brand-500 hover:text-white'
                    }
                    onClick={() => setSettingsModalOpen(false)}
                  >
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_BUTTON_CANCEL')}
                  </button>
                  <button
                    className={
                      'flex items-center justify-center rounded border-[1px] border-brand-500 bg-brand-500 px-4 py-1 font-primary uppercase text-white transition duration-300 hover:bg-opacity-90 hover:text-white'
                    }
                    onClick={saveSettings}
                  >
                    {t('products:COOKIE_CONSENT_MODAL_SETTINGS_BUTTON_SAVE')}
                  </button>
                </Box>
              </div>
            </Modal>
          </>
        )}
    </>
  );
}

interface CookiePreferenceCardProps {
  title: string;
  description: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
}
function CookiePreferenceCard({
  title,
  description,
  isChecked,
  isDisabled = false,
  handleSwitchChange,
  children,
}: CookiePreferenceCardProps) {
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState<Record<string, boolean>>({});

  const toggleDetails = (key: string) => {
    setDetailsOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Paper variant='outlined' sx={{ mb: 2, p: 2 }}>
      <div className='flex items-center justify-between'>
        <div>
          <p className='text-lg'>{title}</p>
          <p className=''>{description}</p>
        </div>
        <BrandSwitch
          checked={isChecked}
          disabled={isDisabled}
          onChange={handleSwitchChange}
        />
      </div>
      <Collapse in={detailsOpen.essential}>{children}</Collapse>
      <button
        className='font-primary text-sm uppercase text-slate-500 opacity-90 hover:bg-slate-100'
        onClick={() => toggleDetails('essential')}
      >
        {detailsOpen.essential
          ? t('products:COOKIE_CONSENT_MODAL_SETTINGS_BUTTON_SHOW_LESS')
          : t('products:COOKIE_CONSENT_MODAL_SETTINGS_BUTTON_LEARN_MORE')}
      </button>
    </Paper>
  );
}
