// TODO: use next image
/* eslint-disable @next/next/no-img-element */
import { IProductAccountManager } from '@/lib/types/api';
import useTranslation from 'next-translate/useTranslation';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { captureMessage } from '@sentry/nextjs';
import { useAccountManagerByLocale } from '@/lib/hooks/useAccountManager';
import { DEFAULT_ACCOUNT_MANAGER } from '@/lib/repository/constants';
import Image from 'next/image';

export function getWhatsappLink(accountManager?: IProductAccountManager) {
  // Sentry error if no phone for the account manager
  if (!accountManager?.phone && accountManager?.name) {
    captureMessage(
      `Phone number of ${accountManager?.email} is not a string: ${
        accountManager.phone
      } - ${typeof accountManager.phone}`,
      {
        level: 'warning',
        extra: {
          accountManager,
        },
      }
    );
  }
  const phone = accountManager?.phone ?? DEFAULT_ACCOUNT_MANAGER.phone;

  return (
    'https://wa.me/+' + phone.toString().replace(/\+/g, '').replace(/ /g, '')
  );
}

export function WhatsAppButton({
  accountManager,
  isSmall,
}: {
  accountManager?: IProductAccountManager;
  isSmall?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <a
      className='whatsapp-text'
      href={getWhatsappLink(accountManager)}
      target='_blank'
      rel='noreferrer'
    >
      <div
        className='flex w-full items-center justify-center  rounded-full bg-green-500 py-2 font-semibold uppercase text-white hover:bg-green-600'
        id='whatsapp-section px-2'
      >
        <div className='mx-2 flex'>
          <Image
            width={20}
            height={20}
            src='https://gindumac.s3.eu-central-1.amazonaws.com/images/email-icons/whatsapp.png'
            id='whatsapp-icon'
            alt='whatsapp'
          />
        </div>
        <div className='mr-2'>
          {isSmall ? (
            <>
              <div className='flex items-center justify-center lg:space-x-2'>
                <span className='hidden lg:block'>{'WhatsApp'}</span>
                <span>{'Chat'}</span>
              </div>
            </>
          ) : (
            t('products:CONTACT_CARD_WHATSAPP_LINK')
          )}
        </div>
      </div>
    </a>
  );
}

export function ProductAccountManager({
  isCentered = false,
  sellingPage = false,
}: {
  isCentered?: boolean;
  sellingPage?: boolean;
}) {
  const { t } = useTranslation();
  const { data: accountManager, isLoading } = useAccountManagerByLocale();
  return (
    <div
      className={`my-8 ${
        isCentered ? 'mx-auto px-4 ' : ''
      }flex max-w-md flex-col space-y-4 font-secondary`}
    >
      <p
        className={`${
          isCentered ? 'text-center' : ''
        } font-light uppercase text-brand-500`}
      >
        {t('products:CONTACT_CARD_TITLE')}
      </p>
      <div
        className={`flex flex-col items-center justify-between space-x-4 md:flex-row ${
          sellingPage ? 'py-[0.8rem]' : ''
        }`}
      >
        {isLoading ? (
          <>
            <div className='h-44 w-44 animate-pulse rounded-full bg-gray-300' />
            <div className='space-y-4'>
              <div className='h-4 w-44 bg-gray-300' />
              <div className='h-4 w-44 bg-gray-300' />
              <div className='h-4 w-44 bg-gray-300' />
            </div>
          </>
        ) : (
          <>
            <div className='relative h-44 w-44 rounded-full border-4 border-brand-500'>
              <Image
                layout='fill'
                objectFit='cover'
                alt='sales contact'
                className='rounded-full'
                src={
                  accountManager?.profile_picture ??
                  'https://s3.eu-central-1.amazonaws.com/gindumac/staff/profile_pictures/borja-garcia.png'
                }
              />
            </div>
            <div className='space-y-4'>
              <p className='contact-info text-center md:text-left'>
                <span className='text-center text-lg font-bold uppercase text-brand-500'>
                  {accountManager?.name ?? 'Borja Garcia'}
                </span>
              </p>
              <p className='flex items-center space-x-2 font-light text-brand-500'>
                <FaPhone className='rotate-90' />
                <span>
                  {accountManager?.phone ?? DEFAULT_ACCOUNT_MANAGER.phone}
                </span>
              </p>
              <p className='flex items-center space-x-2 font-light text-brand-500'>
                <FaEnvelope />
                <span className='uppercase'>
                  {accountManager?.email ?? 'b.garcia@gindumac.com'}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
      <WhatsAppButton accountManager={accountManager} />
    </div>
  );
}
